import { Container, Nav, Navbar} from "react-bootstrap"
import { Link } from "gatsby"
import React, { useState, useEffect } from 'react';
import styled from "@emotion/styled"

const links = [
  { url: "/purchase", title: "purchase" },
  { url: "/composers", title: "composers" },
  { url: "/about", title: "about" },
  { url: "/contact", title: "contact" },
]

export default () => {
  const [url, setUrl] = useState("/");

  useEffect(() => {
    setUrl(typeof window !== 'undefined' ? window.location.pathname : '');
  }, [])

  return (
    <StyledBar className="d-flex" collapseOnSelect expand="lg" sticky="top">
      <Container style={{maxWidth: "90%", width: "90%"}}>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <StyledBrand className="d-lg-none justify-self-end" href="/">
          Mary Monsted
        </StyledBrand>
        <Navbar.Collapse className="justify-content-start" id="responsive-navbar-nav">
          <Nav style={{zIndex: 1}}>
            {
              links.map((l) => {
                return <StyledLink className={url.match(`${l.url}`) ? 'emphasis-header' : '' } to={l.url}>{l.title}</StyledLink>
              })
            }
          </Nav>
          <CenterContainer className=" p-0">
            <StyledBrand className="d-none d-lg-block" href="/">
              Mary Monsted
            </StyledBrand>
          </CenterContainer>
        </Navbar.Collapse>
      </Container>
    </StyledBar>
  )
}

const CenterContainer = styled(Container)`
  justify-content: flex-end !important;
  position: absolute;
  max-width: 86%;
  width:90%;

  @media (min-width: 1500px) {
    display: flex !important;
    justify-content: center !important;
  }
`;

const StyledBar = styled(Navbar)`
  background-color: #ffffff;
  min-height: 103px;
`;

const StyledBrand = styled(Navbar.Brand)`
  color: #4c87a5 !important;
  font-family: "Josefin Sans";
  font-size: 33px;
  font-weight: 700;
  letter-spacing: 0.7px;
  margin: 0;
  text-transform: uppercase;

  @media (max-width: 991px) {
    /* margin: 15px 0; */
  }

  @media (max-width: 420px) {
    font-size: 25px;
  }

  @media (max-width: 420px) {
    font-size: 22px;
  }
`;

const StyledLink = styled(Link)`
  color: #4c87a5 !important;
  font-family: "Josefin Sans";
  font-size: 20px;
  font-weight: 400;
  margin-right: 20px;
  text-transform: uppercase;

  @media (max-width: 991px) {
    margin: 15px 0px;
  }
`;
