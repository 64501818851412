import { Col, Container } from "react-bootstrap"
import React from "react"
import styled from "@emotion/styled"

export default (props) => {
  return(
    <Container fluid className={`center-content content-section px-3 px-md-0 ${props.className || ''}`}>
      <StyledCol maxWidth={props.maxWidth} lg={props.lg || 9}>{props.children}</StyledCol>
    </Container>
  )
}

const StyledCol = styled(Col)`
  max-width: ${props => props.maxWidth || "auto"};
`;
