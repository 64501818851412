import { Container, Nav, Navbar } from "react-bootstrap"
import { Link } from "gatsby"
import React from "react"
import styled from "@emotion/styled"

export default ({ children }) => {
  return (
    <Navbar className="d-flex footer" collapseOnSelect expand="lg" sticky="bottom" style={{minHeight: "130px"}}>
      <Container className="justify-content-between" style={{maxWidth: "90%"}}>
          <Nav id="footer-nav">
            <StyledLink to="/purchase">Purchase</StyledLink>
            <StyledLink to="/composers">Composers</StyledLink>
            <StyledLink to="/about">About</StyledLink>
            <StyledLink to="/contact">Contact Us</StyledLink>
          </Nav>
          <Nav id="social-links" className="ml-5">
            <ExternalLink href="https://pandora.com/"><img src="/pandora.svg" alt="Pandora" /></ExternalLink>
            <ExternalLink href="https://music.amazon.com/"><img src="/amazon-music.svg" alt="Amazon Music" /></ExternalLink>
            <ExternalLink href="https://iheartradio.com/"><img src="/i-heart-radio.svg" alt="iHeart Radio" /></ExternalLink>
            <ExternalLink href="https://music.apple.com/"><img src="/apple-music.svg" alt="Apple Music" /></ExternalLink>
            <ExternalLink href="https://spotify.com/"><img src="/spotify.svg" alt="Spotify" /></ExternalLink>
            <ExternalLink href="https://youtube.com/"><img src="/youtube.svg" alt="Youtube" /></ExternalLink>
          </Nav>
      </Container>
    </Navbar>
  )
}

const StyledLink = styled(Link)`
  color: #f3f2f7 !important;
  font-family: Roboto;
  font-size: 17px;
  font-weight: 300;
  line-height: 21px;
  margin: 10px 0;
  margin-right: 30px;
  text-transform: uppercase;
`;

const ExternalLink = styled(Nav.Link)`
  img {
    margin: 0 3px;
    max-height: 95px;
  }
`;
