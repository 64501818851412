import { Helmet } from "react-helmet"
import NavBar from "./nav-bar"
import React from "react"

export default ({ title="", children }) => {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link rel="preload" href="https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&family=Roboto:wght@300;500&display=swap" as="style" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&family=Roboto:wght@300;500&display=swap" />

        <title>{title}</title>
      </Helmet>
      <NavBar>Navigation</NavBar>
    </>
  )
}
