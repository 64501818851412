import Container from "react-bootstrap/Container"
import Footer from "./footer"
import Header from "./header"
import React from "react"

export default ({ title="", children }) => {
  return (
    <Container fluid className="px-0 theme-light app-container">
      <Header title={title} />
      <main role={"main"} style={{height: "auto", minHeight: "100%"}}>
        {children}
      </main>
      <Footer />
    </Container>
  )
}
